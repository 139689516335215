<template>
  <div class="app">
    <AppHeader fixed>
      <b-link class="navbar-brand" to="/">
        <img
          class="navbar-brand-full d-md-down-none"
          src="@/../public/img/brand/entirelogo.svg"
          width="89"
          height="25"
          alt="Carlbot logo"
        />
        <img
          class="navbar-brand-minimized"
          src="@/../public/img/brand/googleturtle.svg"
          width="30"
          height="30"
          alt="Carlbot logo"
        />
      </b-link>
      <b-navbar-nav class="d-md-down-none nav-title">
        <b-nav-item class="px-2" href="https://docs.carl.gg/">
          <i class="fas fa-book mr-1"></i>Docs
        </b-nav-item>
        <b-nav-item
          class="px-2"
          href="https://discord.com/oauth2/authorize?&client_id=235148962103951360&scope=applications.commands+bot&permissions=2088234230&response_type=code&redirect_uri=https://carl.gg/api/server_auth"
          exact
        >
          <i class="fas fa-plus mr-1"></i>Invite
        </b-nav-item>
        <b-nav-item class="px-2" href="https://discord.gg/S2ZkBTnd8X">
          <i class="fab fa-discord mr-1"></i>Discord
        </b-nav-item>
        <b-nav-item class="px-2" href="https://www.patreon.com/carlbot">
          <i class="fab fa-patreon mr-1"></i>Premium
        </b-nav-item>
        <b-nav-item class="px-2" to="/status">
          <i class="fas fa-heartbeat mr-1"></i>Status
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="d-lg-none nav-title nav-icons">
        <b-nav-item to="/">
          <i class="fas fa-home"></i>
        </b-nav-item>
        <b-nav-item href="https://docs.carl.gg/">
          <i class="fas fa-book"></i>
        </b-nav-item>
        <b-nav-item
          href="https://discord.com/oauth2/authorize?&client_id=235148962103951360&scope=applications.commands+bot&permissions=2088234230&response_type=code&redirect_uri=https://carl.gg/api/server_auth"
          exact
        >
          <i class="fas fa-plus"></i>
        </b-nav-item>
        <b-nav-item href="https://discord.gg/S2ZkBTnd8X" class="d-sm-down-none">
          <i class="fab fa-discord"></i>
        </b-nav-item>
        <b-nav-item href="https://www.patreon.com/carlbot">
          <i class="fab fa-patreon"></i>
        </b-nav-item>
        <b-nav-item to="/status">
          <i class="fas fa-heartbeat"></i>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <div v-if="logged_in">
          <DefaultHeaderDropdownAccnt />
        </div>
        <div v-else class="mr-2">
          <b-button
            style="
              background-color: #3a4149;
              border-color: #eee;
              border-width: 2px;
            "
            v-on:click="login"
            class="px-4 btn-pill dark"
            >Login</b-button
          >
        </div>
      </b-navbar-nav>
      <!--<AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body onboarding__background">
      <div class="onboarding__sidenav d-md-down-none" v-if="showSide">
        <div class="onboarding__logo__container">
          <div class="onboarding__logo">
            <img src="@/../public/img/brand/newcarl.svg" alt="" />
            <b class="onboarding__logo__title">Carl.gg</b>
          </div>
          <img
            class="onboarding__big_logo"
            src="@/../public/img/brand/newcarl.svg"
            alt=""
          />
        </div>
        <img
          class="onboarding__small__icons_one"
          src="@/../public/img/brand/newcarl.svg"
          alt=""
        />
        <img
          class="onboarding__small__icons_two"
          src="@/../public/img/brand/newcarl.svg"
          alt=""
        />
        <img
          class="onboarding__small__icons_three"
          src="@/../public/img/brand/newcarl.svg"
          alt=""
        />
        <img
          class="onboarding__small__icons_four"
          src="@/../public/img/brand/newcarl.svg"
          alt=""
        />

        <img
          class="onboarding__small__icons_five"
          src="@/../public/img/brand/newcarl.svg"
          alt=""
        />

        <img
          class="onboarding__small__icons_six"
          src="@/../public/img/brand/newcarl.svg"
          alt=""
        />

        <div class="onboarding__steps__container">
          <ul>
            <li
              v-for="(el, idx) in progress"
              :key="el.name"
              :class="{
                onboarding__steps__li: true,
                new_main_color: idx <= currentStep
              }"
            >
              <div
                :class="{
                  onboarding__steps__prefix: true,
                  new_main_color: idx <= currentStep
                }"
                :style="{
                  backgroundColor: idx < currentStep ? '#49d6df' : '#0000'
                }"
              >
                <img
                  v-if="idx < currentStep"
                  src="@/../public/img/brand/tick.svg"
                  alt=""
                />

                <div v-else>{{ idx + 1 }}</div>
              </div>
              <div :style="{ fontWeight: idx == currentStep ? 700 : 400 }">
                {{ el.name }}
              </div>
            </li>
          </ul>
        </div>
        <div class="onboarding__art__container">
          <img class="onboarding__art" :src="currentImage" alt="" />
        </div>
      </div>
      <main class="main" style="margin-left: 0px">
        <div style="position: relative; z-index: 1">
          <router-view></router-view>
        </div>
      </main>
      <div
        class="onboarding__sidenav__right d-lg-down-none"
        v-if="showSide"
      ></div>
    </div>
  </div>
</template>

<script>
import { Header as AppHeader } from "@coreui/vue";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";

export default {
  name: "DefaultContainer",
  components: {
    AppHeader,
    DefaultHeaderDropdownAccnt
  },
  data() {
    return {
      discord_user: {},
      progress: [
        { name: "General", art: "general_art" },
        { name: "Welcome Message", art: "welcome_art" },
        { name: "Reaction Roles", art: "role_art" },
        { name: "Moderation", art: "mod_art" },
        { name: "Automod", art: "automod_art" }
      ]
    };
  },
  methods: {
    login() {
      //window.location.href = "http://localhost:5000/api/login"
      window.location.href = window.location.origin +"/api/login";
    },
    updateAuthInfo(e) {
      if (e.data.discriminator != undefined) {
        this.$store.commit("login", e.data);
      }
      window.removeEventListener("message", this.updateAuthInfo);

      this.$router.push("/servers");
    }
  },
  computed: {
    currentStep() {
      return this.$store.state.onboardingStep;
    },
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    },
    logged_in() {
      return Object.keys(this.$store.state.discord_user).length !== 0;
    },
    currentPage() {
      return this.progress[this.currentStep];
    },
    currentImage() {
      let images = require.context("@/../public/img/brand/", false, /\.svg$/);
      return images("./" + this.currentPage.art + ".svg");
    },
    showSide() {
      const route = this.$route;
      return !route.path.endsWith("landing") && !route.path.endsWith("final");
    }
  },
  async created() {
    const guild_id = this.$route.params.guild_id;
    const resp = await this.axios.get(`/api/v1/servers/${guild_id}/onboarding`);
    this.$store.commit("syncOauth", resp.data);
  }
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .onboarding__footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #49d6df;
    position: fixed;
    bottom: 0;
    width: 95vw;
    background-color: #22222a;
  }
}
@media only screen and (min-width: 960px) {
  .onboarding__footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #49d6df;
    position: fixed;
    bottom: 0;
    width: 40vw;
    background-color: #22222a;
  }
}

.onboarding__sidenav {
  width: 400px;
  min-height: 80vh;
  border-right: 3px solid #49d6df;
  background: rgb(41, 42, 51);
  background: linear-gradient(
    90deg,
    rgba(41, 42, 51, 1) 0%,
    rgba(58, 60, 71, 1) 100%
  );
}

.onboarding__sidenav__right {
  width: 80px;
  min-height: 80vh;
  border-left: 3px solid #49d6df;
  background: rgb(93, 98, 115);
  background: linear-gradient(
    90deg,
    rgba(93, 98, 115, 1) 0%,
    rgba(97, 103, 120, 1) 100%
  );
}

.onboarding__small__icons_one {
  position: absolute;
  left: 17.29%;
  right: 14.38%;
  top: 54.43%;
  bottom: 40.51%;

  mix-blend-mode: normal;
  opacity: 0.3;
}

.onboarding__small__icons_two {
  position: absolute;
  left: -0.29%;
  right: 14.38%;
  top: 18.43%;
  bottom: 40.51%;

  mix-blend-mode: normal;
  opacity: 0.3;
}

.onboarding__small__icons_three {
  position: absolute;
  left: 3.29%;
  right: 14.38%;
  top: 24.43%;
  bottom: 40.51%;

  mix-blend-mode: normal;
  opacity: 0.3;
}

.onboarding__small__icons_four {
  position: absolute;
  left: 13.29%;
  right: 14.38%;
  top: 17.43%;
  bottom: 40.51%;

  mix-blend-mode: normal;
  opacity: 0.3;
}

.onboarding__small__icons_five {
  position: absolute;
  left: 1.29%;
  right: 14.38%;
  top: 62.43%;
  bottom: 40.51%;

  mix-blend-mode: normal;
  opacity: 0.3;
}

.onboarding__small__icons_six {
  position: absolute;
  left: 13.29%;
  right: 14.38%;
  top: 77.43%;
  bottom: 40.51%;

  mix-blend-mode: normal;
  opacity: 0.3;
}

.onboarding__logo__container {
  min-height: 20vh;
  display: flex;
  align-items: center;
}

.onboarding__steps__container {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding__steps__li {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #e1f5fe;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.onboarding__background {
  background: #22222a;
}
.onboarding__logo {
  display: flex;
  align-items: center;
  padding-left: 50px;
}

.onboarding__art {
  position: absolute;
  bottom: 0%;
  max-width: 400px;
  max-height: 30vh;
}

.onboarding__big_logo {
  position: absolute;
  float: right;
  width: 160px;
  left: 304px;
  top: 14.11%;
  bottom: 75.11%;
  overflow: hidden;
}

.onboarding__big_logo__right {
  position: absolute;
  width: 160px;
  right: 0px;
  top: 11.11%;
  bottom: 75.11%;
  overflow: hidden;
}

.onboarding__logo__title {
  color: #fefefe;
  font-family: poppins;
  font-size: 20px;
  padding-left: 5px;
}

.onboarding__steps__prefix {
  margin-right: 16px;
  border: 1px solid #e1f5fe;
  border-radius: 50%;
  display: flex;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
}

.new_main_color {
  color: #49d6df;
  border-width: 2px;
  border-color: #49d6df;
}
</style>

<style scoped>
.nav-title .nav-link {
  font-family: "roboto";
  color: #fff;
}
.nav-icons i {
  font-size: 1.075rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect--active {
  z-index: 3 !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  color: #eee !important;
}
.multiselect__input,
.multiselect__single {
  background: #515b65 !important;
  color: #fff !important;
  padding-left: 0px !important;
  padding-top: 2px !important;
  margin-bottom: 11px !important;
}
.multiselect__input::placeholder {
  color: #ddd !important;
  font-size: 14px;
}
.multiselect__tags {
  border: 1px solid #232823 !important;
  background: #515b65 !important;
}
.multiselect__tag {
  background: #2f353a !important;
  border-width: 1px !important;
  border-style: solid;
  border-radius: 500px;
  padding: 5px 10px 4px 26px !important;
  cursor: default;
}

.multiselect__option .multiselect__option--group {
  background-color: #2e343a !important;
  color: #eee;
}

.channelpicker__tag {
  border-radius: 6px !important;
  border-color: #eeeb !important;
}

.multiselect__tag-icon {
  left: -5px !important;
}

.multiselect__tag:hover .multiselect__tag-icon:after {
  content: "\D7";
  color: #fff;
  font-size: 14px;
  margin: -3.5px 0px 10px -4px;
  position: absolute;
}

.multiselect__tag-icon:after {
  content: "\D7";
  color: #fff0;
  font-size: 12px;
  margin: -3.5px 0px 10px -3.5px;
  position: absolute;
}

.multiselect__select:before {
  color: #2f353a !important;
  border-color: #2f353a transparent transparent !important;
}
.defaultcursor {
  cursor: default;
}
.multiselect__placeholder {
  color: #adadad !important;
}
.multiselect--active .multiselect__placeholder {
  color: #eee !important;
}
.multiselect__content-wrapper {
  background: #3a4149 !important;
  border: 1px solid #2f353a !important;
}
.multiselect--above .multiselect__content-wrapper {
  border-top: 1px solid #2f353a !important;
}
.multiselect__option--selected {
  background: #515b65 !important;
}

.fakediscord {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 4px 10px 11px 9px;
  cursor: pointer;
}

.channelpicker_icon {
  background-color: #00000000 !important;
  border-radius: 0;
  border-width: 1px;
  border-style: none solid none none;
  border-color: #eeeb;
  width: 23px;
  height: 25px;
  margin: -1px -4px -20px 4px;
  padding-top: 5px;
}

.channelpicker_icon:after {
  content: "\D7";
  color: #999;
  font-size: 14px;
  margin: -3.5px 0px 10px -4px;
  position: absolute;
}

.multiselect__option--group {
  background-color: #2e343a !important;
  color: #eee !important;
}

.multiselect__option.multiselect__option--selected
  .option__desc
  .option__title {
  color: #bbb !important;
}

.multiselect__spinner {
  background: #515b65 !important;
}

.vc-sketch {
  background-color: #2f353a !important;
  position: absolute !important;
  z-index: 200;
}
.vc-sketch:active {
  user-select: none;
}

.vc-input__input {
  background-color: #515b65 !important;
  color: #eee !important;
  box-shadow: inset 0 0 0 1px #111 !important;
}
.vc-input__label {
  color: #eee !important;
}
.vc-sketch-alpha-wrap {
  display: none;
}
</style>
<style lang="scss">
.form-group-check {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  // align-items: center;
  font-size: 16px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 5px;
  padding: 5px;

  color: #dcf7f9;

  label {
    display: flex;
    align-items: center;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;

    height: 16px;
    width: 16px;
    border-radius: 2px;
    background: none;
    border: 2px solid #999999;
  }

  input[type="checkbox"]:checked {
    background: linear-gradient(270deg, #49d6df 0%, #4cccb5 100%);
    border: none;
  }

  input[type="checkbox"]:hover {
    border: 2px solid #f2f2f2;
  }

  input[type="checkbox"] + .checker {
    path {
      fill: #00000000;
      stroke: #00000000;
    }

    margin-left: -19px;
  }

  input[type="checkbox"]:checked + .checker {
    path {
      fill: #dcf7f9;
      stroke: #dcf7f9;
    }
  }

  .checker,
  svg {
    // fill: #49d6df;
    fill: #00000000;
    stroke: #c5dde0;
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  // Radio
  // The checker for the radio is a simple svg with 2 circles, one used wiht stroke and the other fill
  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="radio"]:checked + .checker {
    fill: #49d6df;
    stroke: #49d6df;
  }

  input[type="radio"]:hover + .checker {
    stroke: #dcf7f9;
  }

  &-inner {
    display: flex;
  }

  .form-extras {
    margin-left: 26px;
  }
}
</style>

<style>
button.saucy-btn {
  background-color: #49d6df;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: white;
  font-family: poppins;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 32px;
  padding-left: 32px;
  margin: 10px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  box-sizing: border-box;
  line-height: normal;
}

.saucy-btn:hover {
  background-color: #25c5d0;
}

.saucy-btn:disabled {
  background-color: #cfd8dc;
}

.saucy-btn-alt {
  background-color: unset;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  border: none;
  color: white;
  font-family: poppins;

  padding: 20px;

  letter-spacing: 0.2em;
}

.saucy-btn-alt:hover {
  background-color: unset;
}
@media only screen and (max-width: 960px) {
  .onboarding__card {
    display: flex;
    width: 95vw;
  }
  .onboardingtitle {
    padding-bottom: 2vh;
    padding-top: 5px;
    text-align: center;
  }
}
@media only screen and (min-width: 960px) {
  .onboarding__card {
    display: flex;
    width: 40vw;
  }
  .onboardingtitle {
    padding-bottom: 9vh;
    padding-top: 20px;
    text-align: center;
  }
}
</style>
